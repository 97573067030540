import React from 'react';
// import { Link } from "gatsby"

import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

const AboutPage = () => (
  <Layout bg="bg-peak">
    <Seo title="Über Uns" />
    <div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500" className="boxed generic-page pt-32">
      <h1 className="lg:!text-5xl ">Das Ziel der Fahrt?</h1>
      <h2>Autofanatiker, Weiterdenker, Macher.</h2>
      <p className="max-w-2xl">
        Unsere Leidenschaft für das Motorisierte mit anderen teilen – das ist die Mission von PEAK MOTORS. Wir lieben Autos. Aber wir glauben daran, Geld nicht in Materielles zu
        investieren, sondern in Erlebnisse. Am besten in solche, die sich teilen lassen und die für immer im Gedächtnis bleiben.
      </p>
    </div>

    <div className="bg-primary-bg">
      <div className="boxed py-24 2xl:max-w-screen-2xl">
        <div className="portrait">
          <div className="col-span-1 lg:flex gap-32">
            <div data-sal="slide-right" data-sal-easing="ease" data-sal-duration="500" data-sal-delay="400">
              <StaticImage src="../assets/images/sam-1.jpg" alt="Samuel Geisler" placeholder="blurred" className="w-full md:w-96" />
            </div>
            <div className="py-12" data-sal="slide-left" data-sal-easing="ease" data-sal-duration="500" data-sal-delay="600">
              <h2 className="font-arboria mb-8 text-5xl border-b border-secondary-font pb-4">Samuel Geisler</h2>
              <ul className="grid grid-cols-2 gap-y-2 gap-x-4 lg:gap-x-24">
                <li>Position</li>
                <li>Geschäftsführer</li>
                <li>Lieblingsfahrzeug</li>
                <li>Porsche GT3</li>
                <li>Traumwagen</li>
                <li>Mc Laren 765LT Spider</li>
                <li>Erstes Auto</li>
                <li>Toyota 4Runner</li>
                <li>Gefahrene Höchstgeschwindigkeit</li>
                <li>290 km/h</li>
                <li>Wichtigstes Ausstattungs-Feature</li>
                <li>Laser- oder LED Matrix Licht</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-peak">
      <div className="boxed py-24 2xl:max-w-screen-2xl">
        <div className="portrait">
          <div className="col-span-1 lg:flex gap-32">
            <div data-sal="slide-right" data-sal-easing="ease" data-sal-duration="500" data-sal-delay="400">
              <StaticImage src="../assets/images/josi-1.jpg" alt="Josef Maier" placeholder="blurred" className="w-full md:w-96" />
            </div>
            <div className="py-12" data-sal="slide-left" data-sal-easing="ease" data-sal-duration="500" data-sal-delay="400">
              <h2 className="font-arboria mb-8 text-5xl border-b border-secondary-font pb-4">Josef Maier</h2>
              <div className="flex justify-between gap-2 lg:gap-24">
                <ul className="grid grid-cols-2 gap-y-2 gap-x-4 lg:gap-x-24">
                  <li>Position</li>
                  <li>Geschäftsführer</li>
                  <li>Lieblingsfahrzeug</li>
                  <li>Porsche GT3RS</li>
                  <li>Traumwagen</li>
                  <li>Mercedes G-Klasse</li>
                  <li>Erstes Auto</li>
                  <li>Opel Corsa in Hellblau 😉</li>
                  <li>Gefahrene Höchstgeschwindigkeit</li>
                  <li>Knapp über 300 km/h</li>
                  <li>Wichtigstes Ausstattungs-Feature</li>
                  <li>Keyless Go</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
